@import "../../../../variables";

.clue-summary {
  padding: 0.5rem;
  
  table {
    border: solid 1px;
    table-layout: auto;
    white-space: nowrap;
    width: 100%;
  }
  
  td {
    width: 25%;
    p {
      margin: 0;
    }
  }

  &.white {
    background: $white;
    table {
      border-color: $black;
    }
    td {
      border: solid 1px $black;
      color: black;
    }
  }

  &:not(.white) {
    background: $black;
    table {
      border-color: $white;
    }
    td {
      border: solid 1px $white;
      color: white;
    }
  }
}