.spy-panel {
  .table {
    margin-bottom: 0.5rem;
  }
  
  .err-spy {
    color: red;
  }
  
  button {
    margin: 1rem 0;
  }
  
  .code {
    font-size: 1.5rem;
    font-weight: bold;
    color: red;
    margin: 1rem 0;
  }
}