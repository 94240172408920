.player-list {
    padding-right: 1rem;
    padding-left: 1rem;
    
    .table, .spec-title {
        margin-top: 0.5rem;
    }
    
    .cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .spy {
        font-weight: bold;
    }
    
    .score {
        padding: 0.2rem;
    }
    
    h3 {
        margin: 0;
    }
}
