@import "../../../../variables";

.clue-sheet {
  padding: 0.5rem;
  table {
    &.header {
      border: none;
      td {
        border: none;
        cursor: pointer;
      }
    }
    border: solid 1px;
  }
  
  &.white {
    background: $white;
    table {
      border-color: $black;
    }
    td {
      border: solid 1px $black;
      color: black;
    }
    .foe {
      background: $black;
      color: $white;
    }
  }
  
  &:not(.white) {
    background: $black;
    table {
      border-color: $white;
    }
    td {
      border: solid 1px $white;
      color: white;
    }
    .foe {
      background: $white;
      color: $black;
    }
  }
  
  .clue {
    overflow: auto;
    word-break: break-word;
  }
  
  .clue {
    width: 85%;
  }
  
  .number {
    width: 5%;
  }
}