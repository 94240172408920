.main-grid {
  width: 100vw;
  height: 100vh;
}

.content {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: #88898d #a5a7ac;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #a5a7ac;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #88898d;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #777777;
}

.credits {
  font-family: Helvetica, serif;
  text-align: center;
  font-size: 0.8rem;
  color: #ccc;

  a {
    color: #00b7b7;
  }
}
