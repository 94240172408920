.result-panel {
  h4 {
    margin: 0;
  }
  
  .code {
    color: cornflowerblue;
  }
  
  .errors {
    color: firebrick;
  }
  
  .successes {
    color: forestgreen;
  }
  
  button {
    margin-top: 1rem;
  }
}