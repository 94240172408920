.word-display {
  overflow-wrap:break-word;
  
  .content-card {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    position: relative;
    
    > span {
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      
      &.number {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        color: red;
      }
    }
  }
}