@import "../../../variables";

.chat {
  background: darken($card-bg, 10);
  
  .content {
    text-align: left;
    max-height: 10rem;
    overflow-y: scroll;
    padding: 1rem 0;
    
    p {
      margin: 0;
    }
    
    .player-name {
      font-weight: bold;
    }
  }
  
  .send-form {
    position: relative;
    
    .message-text {
      display: flex;
      margin-left: 0;
      margin-right: 0;
      width: calc(100% - 2.5rem);
      background: $card-bg;
    }
    
    .send-button {
      cursor: pointer;
      color: black;
      
      &:hover {
        color: lighten(black, 25);
      }
      
      font-size: 1.5rem;
      position: absolute;
      right: 0.2rem;
      top: 50%;
      bottom: 50%;
      transform: translate(-25%, -50%);
    }
  }
}