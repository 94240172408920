@import './variables.scss';

body {
  font-family: Helvetica, serif;
  background: $app-bg;
  margin: 0;
}

.content-card {
  background: $card-bg;
  border-radius: 20px;
  text-align: center;

  padding-top: 1rem;
  padding-bottom: 1rem;

  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

@media (min-width: 960px) {
  .content-card {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
}

@media (max-width: 599px) {
  .xs-none {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .sm-none {
    display: none !important;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .md-none {
    display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .lg-none {
    display: none !important;
  }
}

h2, h1 {
  text-align: center;
  margin: 0;
}

form {
  width: 100%;
}

table {
  table-layout: fixed;
}

.white {
  &.text {
    color: $white;
  }
  &:not(.text) {
    color: #000;
    background-color: $white;
  }
}

.black {
  &.text {
    color: $black;
  }
  &:not(.text) {
    color: #fff;
    background-color: $black;
  }
}
