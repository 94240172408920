.guessing-panel {
  h2 {
    margin-bottom: 1rem;
  }
  
  h3 {
    margin-top: 0;
  }
  
  .spy {
    color: red;
  }

  .foe-sent {
    color: firebrick;
  }
  
  .form-container {
    padding: 0.5rem 0 0.5rem 0;
    border: solid 1px black;
    
    &.mid {
      border-top: none;
      border-bottom: none;
    }
  }
  
  button {
    margin: 1rem 0;
  }

  .MuiOutlinedInput-input {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}